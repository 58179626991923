<template>
    <Sidebar ref="sidebarra" v-model:visible="visibleside" header="Alarmas" position="right" style="width: 35rem;">
        <div class="direct-chat-messages" style="height: 100%;">
            <div class="direct-chat-msg" v-for="alarma in alarmas" :key="alarma">
                <div class="direct-chat-infos clearfix">
                    <a href="#" style="color:blue"
                        @click="iraservicio(alarma.idservicio,alarma.codigo); visibleside = false"
                        class="direct-chat-name float-left">{{ alarma.codigo }}</a>
                    <span class="direct-chat-timestamp float-right">{{ alarma.fecha_alarma
                        }}&nbsp;{{alarma.hora_alarma}}</span>
                </div>
                <img class="direct-chat-img" src="https://plataforma.apuyen.app/img/trabajadora.jpg"
                    alt="message user image">
                <div class="direct-chat-text">

                    <p v-if="alarma.nota_alarma" v-html="alarma.nota_alarma"></p>
                    <hr v-if="alarma.nota_alarma">
                    <p v-html="alarma.texto"></p>
                    <Button style="font-size:0.8rem" @click="alarma.opcion = 'Ya hecho';alarma.posponer=false">Ya
                        hecho</Button>&nbsp;&nbsp;<Button @click="alarma.opcion = 'Contestar';alarma.posponer=false"
                        style="font-size:0.8rem">Contestar</Button>&nbsp;&nbsp;<Button
                        @click="alarma.opcion = 'cerrado';alarma.posponer=true"
                        style="font-size:0.8rem">Posponer</Button><br><br>
                    <Textarea v-if="alarma.opcion != 'cerrado'" v-model="textorespuesta"></Textarea><br>
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div><Button v-if="alarma.opcion != 'cerrado'"
                                style="font-size:0.8rem;background:green;border:1px solid green"
                                @click="desactivaralarma(alarma,textorespuesta,alarma.opcion)">{{ alarma.opcion
                                }}</Button></div>
                    </div>
                    <div v-if="alarma.posponer == true">
                        <div class="row">Fecha:&nbsp;
                            <Calendar v-model="alarma.fechanotapos" :stepMinute="10" showTime hourFormat="24"
                                dateFormat="dd/mm/yy" />
                        </div><br>
                        <div class="row">Motivo:&nbsp;<Textarea v-model="alarma.textoposponer"></Textarea></div><br>
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div><Button style="font-size:0.8rem;background:green;border:1px solid green"
                                    @click="posponeralarma(alarma)">Posponer</Button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Sidebar>
</template>
<script>
import Sidebar from 'primevue/sidebar';
import Textarea from 'primevue/textarea';
import { PwgsApi } from '../services/PwgsApi';
import Calendar from 'primevue/calendar';
export default ({
    props: ['alarmas','visible'],
    setup() {
        return;

    },
    components: {
        Sidebar,
        Textarea,
        Calendar
    },
    data() {
        return {
            visibleside: false,
        }
    },
    methods: {
        iraservicio(idruta,codigo) {
            localStorage.setItem("tiposerv", 'norealizados');
            localStorage.setItem('nombreservicio' + idruta, codigo);
            this.$router.push({
                name: 'Servicio',
                params: {
                    id:idruta
                }
            });
        },
        sacarfecha(alrm) {
            console.log('llegasacar', alrm);
            alrm.posponerfecha = new Date(alrm.fechanotapos).toLocaleDateString("es-ES", { day: 'numeric' }) + "/" + new Date(alrm.fechanotapos).toLocaleDateString("es-ES", { month: 'numeric' }) + "/" + new Date(alrm.fechanotapos).toLocaleDateString("es-ES", { year: 'numeric' });
            alrm.posponerhora = new Date(alrm.fechanotapos).toLocaleTimeString("es-ES", { hour: '2-digit' });
            alrm.posponerminuto = new Date(alrm.fechanotapos).toLocaleTimeString("es-ES", { minute: '2-digit' });
        },
       async desactivaralarma(alrm, txt, opc) {
            const api = new PwgsApi();
           let subidadatos = {};
           if (opc == 'Contestar') { subidadatos = { id_alarma: alrm.id_alarma, texto_alarma: txt }; }
           if (opc == 'Ya hecho') { subidadatos = { id_alarma: alrm.id_alarma }; }
           await api.put('servicios/' + alrm.idservicio + '/notas/' + alrm.id_nota, subidadatos);
           this.visibleside = false;
           this.$router.push('/servicios/' + alrm.idservicio);
           this.$emit('actualizarAlarmas');
       },
        async posponeralarma(alrm) {
            const api = new PwgsApi();
            this.sacarfecha(alrm);
            let subidadatos = { fecha: alrm.posponerfecha, hora: alrm.posponerhora, minuto: alrm.posponerminuto, id_alarma: alrm.id_alarma, texto_alarma: alrm.textoposponer , posponer:1};
            try{
                await api.put('servicios/' + alrm.idservicio + '/notas/' + alrm.id_nota, subidadatos);
                this.$router.push('/servicios/' + alrm.idservicio);
                this.$emit('actualizarAlarmas');
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }
        },
    },
    watch: {
        visible() {
             this.visibleside = true;
        },
        alarmas(){
            for(let alarma of this.alarmas){
                console.log('alarma', alarma);
                alarma.opcion = 'cerrado';
                alarma.posponer = false;
                alarma.fechanotapos = '';
                alarma.posponerfecha= '';
                alarma.posponerhora= '';
                alarma.posponerminuto='';
                alarma.textoposponer='';
            }
        }
    },
    mounted() {
       
    },
})



</script>